import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Carousel from 'common/Carousel';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import FaqContainer from 'containers/FaqContainer';
import AdditionalInfo from 'components/AdditionalInfo';
import InfoBanner from 'components/InfoBanner';

const FaqPage: FC<FaqPageTypes.FaqPageProps> = ({
  data: {
    faqPage: { urls, seo, faqItems, infoBanner, mainCarousel, additionalInfo },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {mainCarousel ? (
        <Carousel
          {...{
            carouselImages: mainCarousel,
            className: 'main-carousel',
          }}
        />
      ) : null}
      <AdditionalInfo {...{ ...additionalInfo }} />
      <FaqContainer {...{ faqItems }} />
      <InfoBanner {...{ infoBanner }} />
    </Layout>
  );
};

export const query = graphql`
  query FaqQuery($lang: String) {
    faqPage(lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      faqItems {
        faqTitle
        accordionItems {
          accordionAnswer
          accordionQuestion
        }
      }
      infoBanner {
        ...InfoBannerTypeFragment
      }
      mainCarousel {
        carouselItems {
          ...CarouselItemsType
        }
      }
      additionalInfo {
        ...AdditionalInfoType
      }
    }
  }
`;

export default FaqPage;
