import React from 'react';

import FaqAccordionItem from './FaqAccordionItem';

import './FaqItem.scss';

const FaqItem = ({
  faqTitle,
  accordionItems,
  faqItemId,
  selectedItem,
  handleToggleAccordion,
}: FaqPageTypes.FaqItemType) => (
  <div className="faq-item" data-testid="faq-item">
    <h2 className="faq-item__title">{faqTitle}</h2>
    {accordionItems.map(
      (
        { accordionQuestion, accordionAnswer }: FaqPageTypes.AccordionItemType,
        faqAccordionItemId: number
      ) => (
        <FaqAccordionItem
          key={accordionQuestion}
          handleToggleAccordion={() => handleToggleAccordion(`${faqAccordionItemId}${faqItemId}`)}
          {...{
            accordionQuestion,
            accordionAnswer,
            isOpen: `${faqAccordionItemId}${faqItemId}` === selectedItem,
          }}
        />
      )
    )}
  </div>
);
export default FaqItem;
