import React, { useState } from 'react';

import FaqItem from 'components/FaqItem';

const FaqContainer = ({ faqItems }: FaqPageTypes.FaqContainerType) => {
  const [selectedItem, setSelectedItem] = useState('');

  const handleToggleAccordion = (activeItem: string) =>
    setSelectedItem(selectedItem === activeItem ? '' : activeItem);

  return (
    <section className="faq-items-container container" data-testid="faq-items-container">
      {faqItems.map(({ faqTitle, accordionItems }, id) => (
        <FaqItem
          key={faqTitle}
          {...{ faqTitle, accordionItems, handleToggleAccordion, faqItemId: id, selectedItem }}
        />
      ))}
    </section>
  );
};

export default FaqContainer;
